* {
    font-family: 'Lato', sans-serif;
}

body {
    padding: 0;
    margin: 0;
}

body.menu-open {
    height: 100%;
    overflow: hidden;
}

.font-lato {
    font-family: 'Lato', sans-serif !important;
}

@media screen and (max-width: 900px) {
    .desktop-only {
        display: none !important;
    }
}

@media screen and (max-width: 900px) {
    .desktop-only {
        display: none !important;
    }
}

@media screen and (min-width: 900px) {
    .mobile-only {
        display: none !important;
    }
}

